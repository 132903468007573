<template>
  <div class="connect-records">
    <el-table :data="records">
      <el-table-column prop="customerName" label="用户" width="120"> </el-table-column>
      <el-table-column prop="roleName" label="职位" width="120"> </el-table-column>
      <el-table-column prop="fieldVal" label="交接项" width="200">
      </el-table-column>
      <el-table-column prop="old_field" label="历史交接人"> </el-table-column>
      <el-table-column prop="field_val" label="最新交接人"> </el-table-column>
      <el-table-column prop="create_time" label="交接时间"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "connect-records",
  props: {
    records: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
.connect-records {
  .el-table__header-wrapper {
    tr {
      th {
        text-align: center;
        background-color: $index-button-color;
        color: #fff;
      }
    }
  }
  .el-table__body-wrapper {
    tr {
      td {
        text-align: center;
      }
    }
  }
}
</style>
