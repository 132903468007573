// 常量

// 客户详情常量
export const clienteleInfo = {
  number: "公司编号",
  contacts: "联系人",
  customer_service: "客服",
  service_id: "客服",
  taxation_method: "征税方式",
  nature: "公司性质",
  contact_tel: "手机号码",
  finance_accounting: "财务会计",
  finance_id: "财务会计",
  corporate_status: "对公情况",
  area_id: "区域",
  spare_contacts: "备用联系人",
  tax_accounting: "税务会计",
  tax_id: "税务会计",
  security_status: "社保情况",
  address: "地址",
  spare_contact_tel: "备用联系电话",
  personal_tax_pass: "个税密码",
  tax_bureau: "所属税务局",
  id_number: "法人身份证",
  susps_reason: "暂停原因",
  first_time: "首次合作时间",
  tax_plate: "税盘",
  tax_discern_num: "纳税识别号",
  electron_tax_pass: "电子税务局密码",
  card_list_pass: "回卡单密码",
};
