<template>
  <!-- 客户详情缴费记录组件 -->
  <div class="paymentreCordCom-container">
    <el-table :data="packageChanges" style="width: 100%">
      <el-table-column label="序号" type="index" align="center" width="80">
      </el-table-column>
      <el-table-column
        prop="term"
        label="服务期限（个月）"
        width="160"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="money" label="缴费金额" align="center">
      </el-table-column>
      <el-table-column prop="pay_type" label="缴费类型" align="center">
      </el-table-column>
      <el-table-column prop="name" label="记录人" align="center">
      </el-table-column>
      <el-table-column prop="start_time" label="缴费时间" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    packageChanges: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      /**
       * business_type:业务类型,
       * payment_amount:缴费金额
       * payment_type:缴费类型
       * note_taker:记录人
       * payment_time:缴费时间
       */
      tableData: [
        {
          business_type: "套餐A",
          payment_amount: "3600元",
          payment_type: "支付宝",
          note_taker: "王灿灿",
          payment_time: "2021.08.26",
        },
        {
          business_type: "套餐A",
          payment_amount: "3600元",
          payment_type: "支付宝",
          note_taker: "王灿灿",
          payment_time: "2021.08.26",
        },
        {
          business_type: "套餐A",
          payment_amount: "3600元",
          payment_type: "支付宝",
          note_taker: "王灿灿",
          payment_time: "2021.08.26",
        },
        {
          business_type: "套餐A",
          payment_amount: "3600元",
          payment_type: "支付宝",
          note_taker: "王灿灿",
          payment_time: "2021.08.26",
        },
        {
          business_type: "套餐A",
          payment_amount: "3600元",
          payment_type: "支付宝",
          note_taker: "王灿灿",
          payment_time: "2021.08.26",
        },
        {
          business_type: "套餐A",
          payment_amount: "3600元",
          payment_type: "支付宝",
          note_taker: "王灿灿",
          payment_time: "2021.08.26",
        },
        {
          business_type: "套餐A",
          payment_amount: "3600元",
          payment_type: "支付宝",
          note_taker: "王灿灿",
          payment_time: "2021.08.26",
        },
        {
          business_type: "套餐A",
          payment_amount: "3600元",
          payment_type: "支付宝",
          note_taker: "王灿灿",
          payment_time: "2021.08.26",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.paymentreCordCom-container {
  .el-table {
    padding: 0z;
  }
  .el-table th {
    background-color: $index-button-color;
    color: #ffffff;
    font-size: 16px;
    .el-icon-arrow-down:before {
      content: "\e790";
      font-size: 16px;
      color: #ffffff;
    }
  }
}
</style>
