<template>
  <div>
    <div class="customerdetails-psContainer">
      <div class="myorderdetails-statetitle">备注内容:</div>
      <!--
      <div class="myorderdetails-psitem">
        <div class="psleft">
          <img src="../../assets/taskavaimg.png" alt="" class="psava" />
          <div class="pscontent">
            <div class="contenttitle">
              <span>天灿灿</span
              ><span style="margin-left: 20px">2021-07-30 15:23:58</span>
            </div>
            <div class="content">客户已通过转账2400元完成结款</div>
            <div class="picture">
              <img src="../../assets/shouju.png" alt="" />
              <img src="../../assets/shouju.png" alt="" />
            </div>
          </div>
        </div>
        <div class="addimgbtn-container">
          <el-button class="addimg">添加图片</el-button>
        </div>
      </div> -->
      <div
          v-for="(item,index) in this.customerlist_remarks"
          :key="item.id"
          class="myorderdetails-psitem"
          @click="getContentId(item.id)"
      >
        <div class="psleft">
          <img alt="" class="psava" src="../../assets/taskavaimg.png"/>
          <div class="pscontent">
            <div class="contenttitle">
              <span>{{ $store.state.adminlist.real_name }}</span
              ><span style="margin-left: 20px">{{ item.create_time }}</span>
            </div>
            <div class="content">
              {{ item.content }}
            </div>
            <div class="picture">
              <img
                  v-for="(item1, index2) in item.images"
                  :key="item1.id"
                  :src="'http://test.admin.tc.qianji.org.cn/' + item1.img_url"
                  alt=""
                  @click="openImg(item1.img_url, index, index2)"
              />
            </div>
          </div>
        </div>
        <div class="addimgbtn-container">
          <button class="addimg" @click="onPickFile">添加图片</button>
          <input
              ref="filePhoto"
              accept="image/*"
              style="display: none;"
              type="file"
              @change="getFile"
          />
        </div>
      </div>
      <div class="addpsbtn-container">
        <el-button class="addpsbtn" @click="addRemarks">添加备注</el-button>
      </div>
    </div>
    <el-dialog
        :visible.sync="RemarksDialogVisible"
        append-to-body
        custom-class="psdialog"
        title="备注内容"
        top="10%"
        width="30%"
    >
      <div class="addUserItem-inputBox">
        <span class="inputTitle">备注内容:</span>
        <el-input
            v-model="addremarkslist.content"
            :autosize="{ minRows: 10, maxRows: 50}"
            placeholder="请输入内容"
            type="textarea"
            @keyup.enter.native="submitRemarks"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="RemarksDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRemarks">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 图片预览 -->
    <el-dialog
        :visible.sync="uploadPhotoData.showPhoto"
        append-to-body
        center
        custom-class="BigImgDialog"
        title="图片预览"
        width="50%"
    >
      <div class="imgbox">
        <img
            :src="uploadPhotoData.imageUrl"
            style="width:100%; object-fil:cover;"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelSending">取 消</el-button>
        <el-button type="primary" @click="beforeUpload(image)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看大图 -->
    <el-dialog
        :visible.sync="ImgDialogVisible"
        append-to-body
        center
        custom-class="BigImgDialog"
        title="大图预览"
        width="50%"
    >
      <div class="imgbox">
        <img :src="BigImgUrl"/>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button id="imgShowUp" circle icon="el-icon-arrow-left" @click="upPhoto"></el-button>
        <label for="imgShowUp" style="margin-left: 0.6rem">上一张</label>
        <span style="padding: 0 2rem">|</span>
        <label for="imgShowDown" style="margin-right: 0.6rem">下一张</label>
        <el-button id="imgShowDown" circle icon="el-icon-arrow-right" @click="nextPhoto"></el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    remarkslist: {
      type: Object,
    },
    remarks: {
      type: Array,
    },
  },
  data() {
    return {
      RemarksDialogVisible: false,
      ImgDialogVisible: false,
      customerlist_remarks: [],
      // customerlist: [],
      addremarkslist: {
        id: "",
        content: "",
        u_id: this.$store.state.adminlist.id,
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token,
      },
      searchinfo: {
        name: "",
      },
      content: [],
      fileList: [],
      uploadURL: "http://admin.gxtc2018.com/admin/Base/upload",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      file: "",
      fileName: "",
      resData: {},
      imglist: [],
      r_id: "",
      img_url: "",
      BigImgUrl: "",
      showIndexs: [],
      uploadPhotoData: {showPhoto: false, imageUrl: ""},
      image: "",
    };
  },
  created() {
    // this.addremarkslist.id = this.remarkslist.id;
    // this.getRemarks();
  },
  mounted() {
    this.addremarkslist.id = this.remarkslist.id;
    this.addremarkslist.u_id = this.$store.state.adminlist.id;
    // console.log(this.addremarkslist.u_id);
    // console.log(this.addremarkslist.id);
    this.getRemarks();
    // this.getcontent()
  },
  computed: {},
  watch: {
    remarkslist: {
      handler(newVal) {
        this.addremarkslist.id = newVal.id;
        // console.log(newVal);
        this.getRemarks();
      },
      deep: true,
    },
  },
  methods: {
    async getRemarks() {
      const res = await this.$http.post(
          "Customer/edit_html",
          this.addremarkslist
      );
      // console.log(this.addremarkslist);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push("./admin");
      }
      if (res.data.code !== 200) return this.$message.error("获取失败!");
      // this.customerlist = res.data.cu;
      // let data = [];
      // data.push(res.data.cu.remarks);
      console.log(res);
      this.$store.commit("SetSet_meal", res.data.cu);
      this.$p;
      this.customerlist_remarks = res.data.cu.remarks;
      // this.imglist.push(res.data.cu.remarks.images)
      this.searchinfo.name = res.data.cu.name;
      console.log(this.customerlist_remarks);
      // console.log(this.imglist);
    },

    addRemarks() {
      this.RemarksDialogVisible = true;
    },
    async submitRemarks() {
      const res = await this.$http.post(
          "Customer/add_remarks",
          this.addremarkslist
      );
      console.log(this.addremarkslist);
      console.log(res);
      // this.getcontent()
      this.addremarkslist.content = "";
      this.RemarksDialogVisible = false;
      this.getRemarks();
    },
    //获取当前备注id
    getContentId(id) {
      console.log("当前备注id：" + id);
      this.r_id = id;
    },
    onPickFile() {
      this.$refs.filePhoto[0].click();
    },
    cancelSending() {
      this.uploadPhotoData.showPhoto = false;
      this.$refs.filePhoto[0].value = "";
    },
    getFile(event) {
      const files = event.target.files;
      let filename = files[0].name; //只有一个文件
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid image!"); //判断图片是否有效
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.uploadPhotoData.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      this.uploadPhotoData.showPhoto = true;
    },
    //上传图片
    beforeUpload(file) {
      console.log(file);
      this.file = file;
      // this.fileName = file.name;
      // console.log(filelist);
      this.resData = {
        // r_id: this.r_id,    //备注id
        // a_id: this.$store.state.adminlist.id,
        // token: this.$store.state.adminlist.token,
        action: "http://admin.gxtc2018.com/admin/Base/upload",
      };
      const formData = new FormData();
      // formData.append("a_id", this.resData.a_id);
      // formData.append("token", this.resData.token);
      // formData.append("r_id", this.resData.r_id);
      formData.append("action", this.resData.action);
      formData.append("image", this.file);
      this.$http({
        method: "post",
        url: "http://admin.gxtc2018.com/admin/Base/upload",
        data: formData,
      })
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg);
              this.img_url = res.data.data;
              // console.log(res);
              console.log(this.img_url);

              this.resData = {
                r_id: this.r_id, //备注id
                a_id: this.$store.state.adminlist.id,
                token: this.$store.state.adminlist.token,
                img_url: this.img_url,
                action:
                    "http://admin.gxtc2018.com/admin/Customer/add_remarks_img",
              };
              const formData = new FormData();
              formData.append("a_id", this.resData.a_id);
              formData.append("token", this.resData.token);
              formData.append("r_id", this.resData.r_id);
              formData.append("action", this.resData.action);
              formData.append("img_url", this.img_url);
              this.$http({
                method: "post",
                url: "http://admin.gxtc2018.com/admin/Customer/add_remarks_img",
                data: formData,
              }).then((res) => {
                // console.log(formData);
                if (res.data.code == 200) {
                  this.$message.success(res.data.msg);
                  console.log(res);
                  this.getRemarks();
                }
              });
            } else if (res.data.code == 10001) {
              this.$router.push("/admin");
              this.$message.error(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
            this.uploadPhotoData.showPhoto = false;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    openImg(url, firstIndex, lastIndex) {
      console.log(url);
      this.BigImgUrl = "http://test.admin.tc.qianji.org.cn/" + url;
      this.showIndexs[0] = firstIndex;
      this.showIndexs[1] = lastIndex;
      console.log(this.BigImgUrl);
      this.ImgDialogVisible = true;
    },
    nextPhoto() {
      const [firstIndex, lastIndex] = this.showIndexs;
      if ((lastIndex + 1) === this.customerlist_remarks[firstIndex].images.length) {
        console.log("最后一张");
      } else {
        this.openImg(this.customerlist_remarks[firstIndex].images[lastIndex + 1].img_url, firstIndex, lastIndex + 1);
      }
    },
    upPhoto() {
      const [firstIndex, lastIndex] = this.showIndexs;
      if (lastIndex === 0) {
        console.log("最顶一张");
      } else {
        this.openImg(this.customerlist_remarks[firstIndex].images[lastIndex - 1].img_url, firstIndex, lastIndex - 1);
      }
    }

    // async getcontent() {
    //   const ret = await this.$http.get(
    //     "http://tc.qianji.org.cn/admin/Customer/index",
    //     { params: this.searchinfo }
    //   );
    //   // console.log(ret);
    //   this.customerlist_remarks = ret.data.data;
    //   // console.log(this.customerlist_remarks);
    // }
  },
};
</script>

<style lang="scss">
.customerdetails-psContainer {
  padding: 30px 0;

  .myorderdetails-psitem:hover {
    background-color: #f9f9f9;
  }

  .myorderdetails-psitem {
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .psleft {
      display: flex;

      .pscontent {
        width: 1000px;
        overflow: hidden;
      }

      .psava {
        width: 40px;
        height: 40px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .contenttitle,
      .content {
        margin-bottom: 2px;
      }

      .picture {
        // width: 80px;
        // height: 30px;
        display: flex;

        img {
          width: 6rem;
          height: 4rem;
          margin-right: 5px;
          object-fit: contain;
          transition: all 0.5s ease-in-out;
        }
      }
    }

    .addimg {
      padding: 0.6rem 0.8rem;
      background-color: $index-buttonPor-color;
      color: #ffffff;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }

  .addpsbtn-container {
    text-align: center;
    padding-top: 10px;

    .addpsbtn {
      background-color: $index-buttonPor-color;
      color: #ffffff;
    }
  }
}

.BigImgDialog {
  .imgbox {
    max-width: 80vw;
    max-height: 80vh;
    display: flex;
    align-content: center;
    justify-content: center;
    // padding: 80px 0px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.el-dialog {
  .el-dialog__header {
    background-color: #8674eb;

    .el-dialog__title {
      color: #fff;
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }

  .el-dialog__footer {
    .el-button--primary {
      color: #fff;
      background-color: #8674eb;
      border-color: #8674eb;
    }
  }
}

.photo_box {
  display: block;
  max-width: 70vw;
  max-height: 80vh;
}
</style>
