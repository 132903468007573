<template>
  <div class="amendant-record">
    <el-table :data="records" style="width: 100%">
      <el-table-column prop="customerName" label="用户" width="120"> </el-table-column>
      <el-table-column prop="roleName" label="职位" width="120"> </el-table-column>
      <el-table-column prop="fieldVal" label="修改项" width="200">
      </el-table-column>
      <el-table-column prop="old_field" label="修改前"> </el-table-column>
      <el-table-column prop="field_val" label="修改后"> </el-table-column>
      <el-table-column prop="create_time" label="修改时间"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "amendant-record",
  props: {
    records: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
.amendant-record {
  .el-table__header-wrapper {
    tr {
      th {
        text-align: center;
        background-color: $index-button-color;
        color: #fff;
      }
    }
  }
  .el-table__body-wrapper {
    tr {
      td {
        text-align: center;
      }
    }
  }
}
</style>
