<template>
  <div class="amend-set_meal-dilog">
    <el-dialog
      width="30%"
      top="10%"
      title="修改套餐"
      :visible="showAmendSetmealDialog"
      @close="quitDialog"
      append-to-body
      custom-class="messagediglog-box"
    >
      <el-form :v-model="set_meal">
        <!-- 本期套餐 -->
        <el-form-item class="addUserItem-inputBox" label="本期套餐:">
          <!-- <span class="inputTitle">本期套餐:</span> -->
          <el-input v-model="set_meal.pay_money" @blur="computeRate"></el-input>
        </el-form-item>
        <!-- 服务期限(个月) -->
        <el-form-item
          class="addUserItem-inputBox"
          label="服务期限(个月):"
          prop="term"
        >
          <!-- <span class="inputTitle">服务期限(个月):</span> -->
          <el-input-number
            v-model="set_meal.term"
            controls-position="right"
            @change="handleChange"
            :min="1"
            :max="100"
          ></el-input-number>
        </el-form-item>
        <!-- 月费率 -->
        <el-form-item class="addUserItem-inputBox" label="月费率:">
          <!-- <span class="inputTitle">月费率:</span> -->
          <div class="addUserItemPrice">
            {{
              set_meal.pay_money
                ? (set_meal.pay_money / set_meal.term).toFixed(2)
                : `0.00 `
            }}元/月
          </div>
        </el-form-item>
        <!-- 开始时间 -->
        <el-form-item
          class="addUserItem-inputBox"
          label="开始时间:"
          prop="start_time"
        >
          <!-- <span class="inputTitle">开始时间:</span> -->
          <el-date-picker
            v-model="set_meal.start_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 结束时间 -->
        <el-form-item
          class="addUserItem-inputBox"
          label="结束时间:"
          prop="end_time"
        >
          <!-- <span class="inputTitle">结束时间:</span> -->
          <el-date-picker
            v-model="set_meal.end_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          class="addUserItem-inputBox"
          label="缴费类型:"
          prop="pay_type"
        >
          <!-- <span class="inputTitle">缴费类型:</span> -->
          <el-select v-model="set_meal.pay_type" placeholder="缴费类型">
            <el-option
              v-for="item in paymenttypeList"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quitDialog">取 消</el-button>
        <el-button type="primary" @click="subSetmeal">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "amend-set_meal-dilog",
  props: {
    showAmendSetmealDialog: {
      type: Boolean,
      default: false,
    },
    u_id: {
      type: Number,
      default() {
        return 0;
      },
    },
    str: {
      type: String,
      default() {
        return "";
      },
    },
  },
  updated() {
    this.set_meal = this.$store.state.set_meal;
  },
  data() {
    return {
      set_meal: {
        term: "",
        start_time: "",
        end_time: "",
        pay_money: "",
        pay_type: "",
      },
      // 缴费类型下拉选择列表
      paymenttypeList: [
        { text: "微信", value: "微信" },
        { text: "支付宝", value: "支付宝" },
        { text: "现金", value: "现金" },
        { text: "工商", value: "工商" },
        { text: "农行", value: "农行" },
        { text: "对公", value: "对公" },
        { text: "建行", value: "建行" },
        { text: "农行收款码", value: "农行收款码" },
      ],
    };
  },

  watch: {
    set_meal(newval, value) {
      console.log("watch", newval, value);
    },
  },
  methods: {
    //   退出对话框的回调
    quitDialog() {
      this.$emit("quitDialog", false);
    },
    // 确认提交套餐
    async subSetmeal() {
      const { start_time, end_time, pay_money, pay_type, term } = this.set_meal;
      const { id: a_id } = this.$store.getters.adminlist;
      const {
        data: { code, msg },
      } = await this.$http.post("/SetMeal/updateSetMeal", {
        uid: this.u_id,
        money: pay_money,
        pay_type,
        term,
        start_time,
        end_time,
        a_id,
      });
      if (!code === 200) return this.$message.error(msg);
      this.$emit("subSetmeal");
      this.$emit("updateSetMeal", this.set_meal);
      this.$message.success(msg);
      this.quitDialog();
    },
    //
    computeRate() {},
    //
    handleChange() {},
    //
    // format(val) {},
  },
};
</script>
