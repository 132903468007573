<template>
  <div id="adduserCom">
    <el-dialog
      append-to-body
      title="创建客户"
      :visible.sync="dialogVisible"
      width="1300px"
      :before-close="dialogVisibleClose"
      @close="dialogVisibleClose"
      class="adduserCom-dialog"
    >
      <!-- 内容区 -->
      <el-form
        class="addUser-Container"
        :model="addlist"
        :rules="addFormRules"
        ref="addFormRef"
      >
        <!-- 基本信息 -->
        <div class="addUserItem">
          <div class="addUserItem-titie">基本信息</div>
          <div class="addUsetItem-container">
            <!-- 公司编号 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="公司编号:"
              prop="number"
            >
              <!-- <span class="inputTitle">公司编号:</span> -->
              <el-input
                v-model="addlist.number"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 联系人 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="联系人:"
              prop="contacts"
            >
              <!-- <span class="inputTitle"> <i>*</i> 联系人:</span> -->
              <el-input
                v-model="addlist.contacts"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 公司名称 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="公司名称:"
              prop="name"
            >
              <!-- <span class="inputTitle"> <i>*</i> 公司名称:</span> -->
              <el-input
                v-model="addlist.name"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 手机号码 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="手机号码:"
              prop="contact_tel"
            >
              <!-- <span class="inputTitle"> <i>*</i> 手机号码:</span> -->
              <el-input
                v-model="addlist.contact_tel"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 所属片区 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="所属片区:"
              prop="area_id"
            >
              <!-- <span class="inputTitle"> <i>*</i> 所属片区:</span> -->
              <el-select
                v-model="addlist.area_id"
                placeholder="请选择"
                @change="onSelected_Area($event)"
              >
                <el-option
                  v-for="item in areaOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 备用联系人 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="备用联系人:"
              prop="spare_contacts"
            >
              <!-- <span class="inputTitle">备用联系人:</span> -->
              <el-input
                v-model="addlist.spare_contacts"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 公司地址 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="公司地址:"
              prop="address"
            >
              <!-- <span class="inputTitle">公司地址:</span> -->
              <el-input
                v-model="addlist.address"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 备用联系电话 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="备用人联系电话:"
              prop="spare_contact_tel"
            >
              <!-- <span class="inputTitle">备用人联系电话:</span> -->
              <el-input
                v-model="addlist.spare_contact_tel"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 法人姓名 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="法人姓名:"
              prop="legal_person"
            >
              <!-- <span class="inputTitle">法人姓名:</span> -->
              <el-input
                v-model="addlist.legal_person"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 选择办理流程 -->
            <!-- <div class="addUserItem-inputBox">
              <span class="inputTitle"> <i>*</i> 选择办理流程:</span>
              <el-select v-model="addlist.area_id" placeholder="请选择所属片区">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
            <!-- 法人身份证 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="法人身份证:"
              prop="id_number"
            >
              <!-- <span class="inputTitle">法人身份证:</span> -->
              <el-input
                v-model="addlist.id_number"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 备注 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="备注:"
              prop="remarks"
            >
              <!-- <span class="inputTitle">备注:</span> -->
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="addlist.remarks"
              >
              </el-input>
            </el-form-item>
            <!-- 公司性质 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="公司性质:"
              prop="nature"
            >
              <!-- <span class="inputTitle"> <i>*</i> 公司性质:</span> -->
              <el-select v-model="addlist.nature" placeholder="请选择公司性质">
                <el-option
                  v-for="item in NatureList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <!-- 业务信息 -->
        <div class="addUserItem">
          <div class="addUserItem-titie">业务信息</div>
          <div class="addUsetItem-container">
            <!-- 本期套餐 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="本期套餐:"
              prop="pay_money"
            >
              <!-- <span class="inputTitle">本期套餐:</span> -->
              <el-input
                v-model="addlist.pay_money"
                @blur="computeRate"
              ></el-input>
            </el-form-item>
            <!-- 服务期限(个月) -->
            <el-form-item
              class="addUserItem-inputBox"
              label="服务期限(个月):"
              prop="term"
            >
              <!-- <span class="inputTitle">服务期限(个月):</span> -->
              <el-input-number
                v-model="addlist.term"
                controls-position="right"
                @change="handleChange"
                :min="1"
                :max="100"
              ></el-input-number>
            </el-form-item>
            <!-- 月费率 -->
            <el-form-item class="addUserItem-inputBox" label="月费率:">
              <!-- <span class="inputTitle">月费率:</span> -->
              <div class="addUserItemPrice">{{ this.fee_rate }}元/月</div>
            </el-form-item>
            <!-- 开始时间 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="开始时间:"
              prop="start_time"
            >
              <!-- <span class="inputTitle">开始时间:</span> -->
              <el-date-picker
                v-model="addlist.start_time"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <!-- 结束时间 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="结束时间:"
              prop="end_time"
            >
              <!-- <span class="inputTitle">结束时间:</span> -->
              <el-date-picker
                v-model="addlist.end_time"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <!-- -->
            <el-form-item
              class="addUserItem-inputBox"
              label="缴费类型:"
              prop="pay_type"
            >
              <!-- <span class="inputTitle">缴费类型:</span> -->
              <el-select v-model="addlist.pay_type" placeholder="缴费类型">
                <el-option
                  v-for="item in paymenttypeList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <!-- 其他信息 -->
        <!-- 业务信息 -->
        <div class="addUserItem">
          <div class="addUserItem-titie">其他信息</div>
          <div class="addUsetItem-container">
            <!-- 首次合作时间 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="首次合作时间:"
              prop="first_time"
            >
              <!-- <span class="inputTitle">首次合作时间:</span> -->
              <el-date-picker
                v-model="addlist.first_time"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <!-- 客服 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="客服:"
              prop="service_id"
            >
              <!-- <span class="inputTitle">客服:</span> -->
              <el-select
                v-model="addlist.service_id"
                filterable
                placeholder="请选择"
                @change="onSelectedDrug($event)"
              >
                <el-option
                  v-for="item in customer_servicelist"
                  :key="item.id"
                  :label="item.real_name + '(' + item.area_name + ')'"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 所属税务员-->
            <el-form-item
              class="addUserItem-inputBox"
              label="所属税务局:"
              prop="tax_bureau"
            >
              <!-- <span class="inputTitle">所属税务局:</span> -->
              <el-input
                v-model="addlist.tax_bureau"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 税务会计 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="税务会计:"
              prop="tax_id"
            >
              <!-- <span class="inputTitle">税务会计:</span> -->
              <el-select
                v-model="addlist.tax_id"
                filterable
                placeholder="请选择"
                @change="onSelected_Tax($event)"
              >
                <el-option
                  v-for="item in tax_list"
                  :key="item.id"
                  :label="item.real_name + '(' + item.area_name + ')'"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 税盘 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="税盘:"
              prop="tax_plate"
            >
              <!-- <span class="inputTitle">税盘:</span> -->
              <el-input
                v-model="addlist.tax_plate"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 账务会计 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="账务会计:"
              prop="finance_id"
            >
              <!-- <span class="inputTitle">财务会计:</span> -->
              <el-select
                v-model="addlist.finance_id"
                filterable
                placeholder="请选择"
                @change="onSelected_Finance($event)"
              >
                <el-option
                  v-for="item in finance_list"
                  :key="item.id"
                  :label="item.real_name + '(' + item.area_name + ')'"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 社保情况 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="社保情况:"
              prop="security_status"
            >
              <!-- <span class="inputTitle">社保情况:</span> -->
              <el-select v-model="addlist.security_status">
                <el-option
                  v-for="item in security_status"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 对公情况 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="对公情况:"
              prop="corporate_status"
            >
              <!-- <span class="inputTitle">社保情况:</span> -->
              <el-select v-model="addlist.corporate_status">
                <el-option
                  v-for="item in corporate_status"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 回卡单密码 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="回卡单密码:"
              prop="card_list_pass"
            >
              <!-- <span class="inputTitle">回卡单密码:</span> -->
              <el-input
                v-model="addlist.card_list_pass"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 征税方式 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="征税方式:"
              prop="taxation_method"
            >
              <!-- <span class="inputTitle">征税方式:</span> -->

              <el-select v-model="addlist.taxation_method">
                <el-option
                  v-for="item in taxation_method"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 个税密码 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="个税密码:"
              prop="personal_tax_pass"
            >
              <!-- <span class="inputTitle">个税密码:</span> -->
              <el-input
                v-model="addlist.personal_tax_pass"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 电子税务局密码 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="电子税务局密码:"
              prop="electron_tax_pass"
            >
              <!-- <span class="inputTitle">电子税务局密码:</span> -->
              <el-input
                v-model="addlist.electron_tax_pass"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <!-- 纳税识别号 -->
            <el-form-item
              class="addUserItem-inputBox"
              label="纳税识别号:"
              prop="tax_discern_num"
            >
              <!-- <span class="inputTitle">纳税识别号:</span> -->
              <el-input
                v-model="addlist.tax_discern_num"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <!-- 按钮区 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleClose">取 消</el-button>
        <el-button type="primary" @click="addCustomer">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    getcustomerList: {
      type: Function
    }
  },
  data() {
    //验证手机号码的规则
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[34578]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      //返回一个错误提示
      cb(new Error('请输入合法的手机号码'))
    }
    return {
      num: "",
      areaOptions: [],
      addlist: {
        number: "",
        contacts: "",
        name: "",
        contact_tel: "",
        spare_contacts: "",
        address: "",
        spare_contact_tel: "",
        legal_person: "",
        area_id: "",
        id_number: "",
        remarks: "",
        nature: "",
        // set_meal: "",
        // term: "" /*合同期限 */,
        term: '',/*服务期限*/
        /*月费率*/
        start_time: "",
        end_time: "",
        pay_type: "",
        pay_money: "", //本期套餐
        //办理流程
        first_time: "",
        customer_service: "", //客服名字
        tax_bureau: "",//所属税务局
        tax_accounting: "", //税务会计名字
        tax_plate: "",
        finance_accounting: "", //账务会计名字
        security_status: "1", //社保情况，有无
        corporate_status: "1",//对公情况，有无
        card_list_pass: "",
        taxation_method: "",
        personal_tax_pass: "",
        electron_tax_pass: "",
        tax_discern_num: "",
        service_id: "", //客服id
        tax_id: "", //税务会计id
        finance_id: "", //账务会计id
        note_taker_id: this.$store.state.adminlist.id,//记录人员id
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token
      },
      fee_rate: '0',
      customer_servicelist: [],//客服列表
      tax_list: [],//税务会计列表
      finance_list: [],//财务会计列表
      security_status: [
        { text: "有", value: "1" },
        { text: "无", value: "2" }
      ],
      corporate_status: [
        { text: "有", value: "1" },
        { text: "无", value: "2" }
      ],
      NatureList: [
        { text: "小规模", value: "1" },
        { text: "一般纳税人", value: "2" },
        { text: "小规模个体户", value: "3" },
        { text: "一般纳税人个体户", value: "4" }
      ],
      currentpackageList: [
        { text: "套餐一", value: "1" },
        { text: "套餐二", value: "2" },
        { text: "套餐三", value: "3" },
        { text: "套餐四", value: "4" }
      ],
      paymenttypeList: [
        { text: "微信", value: "微信" },
        { text: "支付宝", value: "支付宝" },
        { text: "现金", value: "现金" },
        { text: "工商", value: "工商" },
        { text: "农行", value: "农行" },
        { text: "建行", value: "建行" },
        { text: "对公", value: "对公" },
        { text: "农行收款码", value: "农行收款码" }
      ],
      taxation_method: [
        { text: "查账征收", value: "查账征收" },
        { text: "简易征收", value: "简易征收" },
        { text: "差额征收", value: "差额征收" },
        { text: "免税征收", value: "免税征收" },
        { text: "定率征收", value: "定率征收" },
        { text: "定期定额征收", value: "定期定额征收" }
      ],
      queryInfo: {
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token
      },
      addFormRules: {
        name: [
          { required: true, message: '请输入客户(公司)名称', trigger: 'blur' },
          {
            // min: 3,
            // max: 10,
            // message: '用户名在3~10个字符之间',
            trigger: 'blur'
          }
        ],
        number: [
          { required: true, message: '请输入公司编号', trigger: 'blur' },
          {
            // min: 6,
            // max: 15,
            // message: '用户名在6~15个字符之间',
            trigger: 'blur'
          }
        ],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          {
            trigger: 'blur'
          }
        ],
        area_id: [
          { required: true, message: '请选择片区', trigger: 'blur' },
          {
            trigger: 'blur',
            type: 'number'
          }
        ],
        // email: [
        //   { required: true, message: '请输入邮箱', trigger: 'blur' },
        //   { validator: checkEmail, message: '邮箱格式不正确，请重新输入', trigger: 'blur' }
        // ],
        contact_tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: checkMobile, message: '请正确输入合法的手机号', trigger: 'blur' }
        ],
        // spare_contact_tel: [
        //   // { required: true, message: '请输入手机号码', trigger: 'blur' },
        //   { validator: checkMobile, message: '请正确输入合法的手机号', trigger: 'blur' }
        // ]
      }
    };
  },
  mounted() {
    this.getAreaList();
    this.getCustomerService();
    this.gettaxlist();
    this.getfinancelist();
  },

  methods: {
    addCustomer() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return this.$message({
          showClose: true,
          message: '请填写客户完整信息',
          type: 'error',
          offset: 500
        })
        const res = await this.$http.post(
          "Customer/add",
          this.addlist
        );

        // console.log(res);
        if (res.data.code === 10001) {
          this.$message.error(res.data.msg);
          this.$router.push('./admin')
        }
        if (res.status !== 200 || res.data.code === 301)
          return this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error',
            offset: 500
          });
        this.$message({
          showClose: true,
          message: res.data.msg,
          type: 'success',
          offset: 500
        });
        this.dialogVisibleClose();
        this.getcustomerList();

      })
    },
    //获取片区
    async getAreaList() {
      const res = await this.$http.post("Area/getAreaList", this.queryInfo)
      console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 200) return this.$message.error(res.data.msg);
      this.areaOptions = res.data.data
      if (this.$store.state.adminlist.role_id === 4 || this.$store.state.adminlist.role_id === 5 || this.$store.state.adminlist.role_id === 9 || this.$store.state.adminlist.role_id === 11) {
        this.addlist.area_id = this.$store.state.adminlist.area_id
      }
    },
    //获取客服
    async getCustomerService() {
      const res = await this.$http.get(
        "Admin/getService",
        { params: this.queryInfo }
      );
      // console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 200) return this.$message.error(res.data.msg);
      this.customer_servicelist = res.data.data;
      if (this.$store.state.adminlist.role_id === 5) {
        this.addlist.service_id = this.$store.state.adminlist.id
        this.addlist.customer_service = this.$store.state.adminlist.real_name
      }
    },
    //获取税务会计
    async gettaxlist() {
      const res = await this.$http.get('Admin/getTaxAccounting', { params: this.queryInfo })
      // console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 200) return this.$message.error(res.data.msg);
      this.tax_list = res.data.data
      if (this.$store.state.adminlist.role_id === 4) {
        this.addlist.tax_id = this.$store.state.adminlist.id
        this.addlist.tax_accounting = this.$store.state.adminlist.real_name
      }
    },
    //获取账务会计
    async getfinancelist() {
      const res = await this.$http.get('Admin/getFinanceAccounting', { params: this.queryInfo })
      // console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 200) return this.$message.error(res.data.msg);
      this.finance_list = res.data.data
      if (this.$store.state.adminlist.role_id === 11) {
        this.addlist.finance_id = this.$store.state.adminlist.id
        this.addlist.finance_accounting = this.$store.state.adminlist.real_name
      }
    },
    //计算月费率
    computeRate() {
      const money = this.addlist.pay_money
      const month = this.addlist.term
      const rate = money / month
      this.fee_rate = rate.toFixed(2)
    },
    //片区下拉改变
    onSelected_Area(e) {
      console.log(this.addlist.area_id);
      console.log(typeof this.addlist.area_id);
      console.log(e);//获取的id
    },
    //客服下拉改变
    onSelectedDrug(e) {
      let obj = {};
      obj = this.customer_servicelist.find((item) => {//这里的customer_servicelist就是上面遍历的数据源
        return item.id === e;//筛选出匹配数据
      });
      console.log(obj.real_name);//获取的 name
      this.addlist.customer_service = obj.real_name
      console.log(e);//获取的 id
    },
    //税务会计下拉改变
    onSelected_Tax(e) {
      let obj = {};
      obj = this.tax_list.find((item) => {//这里的tax_list就是上面遍历的数据源
        return item.id === e;//筛选出匹配数据
      });
      console.log(obj.real_name);//获取的 name
      this.addlist.tax_accounting = obj.real_name
      console.log(e);//获取的 id
    },
    //账务会计下拉改变
    onSelected_Finance(e) {
      let obj = {};
      obj = this.finance_list.find((item) => {//这里的finance_list就是上面遍历的数据源
        return item.id === e;//筛选出匹配数据
      });
      console.log(obj.real_name);//获取的 name
      this.addlist.finance_accounting = obj.real_name
      console.log(e);//获取的 id
      // console.log(this.addlist.finance_accounting);
    },
    // handleClose(done) {
    //   this.$confirm("确认关闭？")
    //     .then(() => {
    //       done();
    //     })
    //     .catch(() => {
    //       // this.$emit('dialogVisibleClose', false)
    //     });
    // },
    dialogVisibleClose() {
      // console.log('dialogVisibleClose的回调');
      this.$emit("dialogVisibleClose");
      this.$refs.addFormRef.resetFields()
    },
    handleChange() {
      this.computeRate()
    }
  }
};
</script>

<style lang="scss">
.adduserCom-dialog {
  .el-dialog {
    position: relative;
    .el-dialog__header {
      background-color: $index-buttonPor-color;
      .el-dialog__title {
        color: #ffffff;
        font-size: 20px;
        font-weight: bold;
      }
      .el-dialog__close {
        color: #ffffff;
      }
    }
    .el-dialog__body {
      ::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 2px;
      }
      ::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/

        border-radius: 5px;

        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

        background: $index-buttonPor-color;
      }
      ::-webkit-scrollbar-track {
        /*滚动条里面轨道*/

        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

        border-radius: 0;

        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
  .addUser-Container {
    padding: 0 50px;
    height: 800px;
    overflow: auto;
    .addUserItem {
      padding: 50px 0;
      .addUserItem-titie {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }
  .addUsetItem-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 200px;
    .el-form-item {
      margin-bottom: 0px;
      .el-form-item__label {
        text-align: left;
        width: 120px;
        color: #333333;
        font-weight: bold;
      }
      .el-input {
        width: 375px;
      }
      .el-input-number {
        width: 100%;
      }
      .el-textarea__inner {
        width: 375px;
      }
    }
    .addUserItem-inputBox {
      display: flex;
      align-items: center;
      .addUserItemPrice {
        color: #333333;
      }
      .inputTitle {
        font-weight: bold;
        color: #333333;
        width: 120px;
        font-size: 14px;
      }
      .el-input,
      .el-select,
      .el-textarea,
      .el-input-number {
        font-weight: bold;
        font-size: 14px;
        color: #333333;
        flex: 1;
      }
    }
  }
}
</style>
