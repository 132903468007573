<template>
  <!-- 客户管理 -->
  <div>
    <el-table :data="customerList" style="width: 100%; height: 100%">
      <!-- 序号 -->
      <el-table-column
        fixed
        label="序号"
        width="100"
        align="center"
        type="index"
      >
      </el-table-column>
      <!-- 区域 -->

      <el-table-column prop="area_name" label="区域" width="180" align="center">
      </el-table-column>
      <!-- 公司编号 -->
      <el-table-column
        prop="number"
        label="公司编号"
        width="180"
        align="center"
      >
      </el-table-column>
      <!-- 公司名称 -->
      <el-table-column
        prop="name"
        label="公司名称"
        width="180"
        align="center"
        fixed
      >
      </el-table-column>
      <!-- 公司性质 -->
      <el-table-column
        prop="nature"
        label="公司性质"
        width="180"
        align="center"
        :filters="NatureList"
        :filter-method="filterNatureList"
        filter-placement="bottom-end"
      >
        <!-- 通过 Scoped slot 可以获取到 row, column, $index 和 store（table 内部的状态管理）的数据，用法参考 demo。 -->
        <template slot-scope="scope">
          {{
            scope.row.nature == "1"
              ? "小规模"
              : scope.row.nature == "2"
              ? "一般纳税人"
              : scope.row.nature == "3"
              ? "小规模个体户"
              : scope.row.nature == "4"
              ? "一般纳税个体户"
              : ""
          }}
        </template>
      </el-table-column>
      <!-- 本期套餐 -->
      <el-table-column
        prop="pay_money"
        label="本期套餐"
        width="180"
        align="center"
        filter-placement="bottom-end"
      >
      </el-table-column>
      <!-- 付款类型 -->
      <el-table-column
        prop="pay_type"
        label="付款类型"
        width="180"
        align="center"
        :filters="paymenttypeList"
        :filter-method="filterpaymenttype"
        filter-placement="bottom-end"
      >
        <!-- 通过 Scoped slot 可以获取到 row, column, $index 和 store（table 内部的状态管理）的数据，用法参考 demo。 -->
        <!-- 每一行的显示 -->

        <template slot-scope="scope">
          {{
            scope.row.pay_type == "微信"
              ? "微信"
              : scope.row.pay_type == "支付宝"
              ? "支付宝"
              : scope.row.pay_type == "现金"
              ? "现金"
              : scope.row.pay_type == "工商"
              ? "工商"
              : scope.row.pay_type == "农行"
              ? "农行"
              : scope.row.pay_type == "建行"
              ? "建行"
              : scope.row.pay_type == "对公"
              ? "对公"
              : scope.row.pay_type == "农行收款码"
              ? "农行收款码"
              : ""
          }}
        </template>
      </el-table-column>

      <!-- 客服 -->
      <el-table-column
        prop="customer_service"
        label="客服"
        width="180"
        align="center"
      >
        <!-- <template>
          <span
            v-if="this.$store.state.adminlist.role.name !== '客服主管'"
            style="color: red"
            >***无权限查看***</span
          >
        </template> -->
      </el-table-column>
      <!-- 税务会计 -->
      <el-table-column
        prop="tax_accounting"
        label="税务会计"
        width="180"
        align="center"
      >
      </el-table-column>
      <!-- 账务会计 -->
      <el-table-column
        prop="finance_accounting"
        label="账务会计"
        width="180"
        align="center"
      >
      </el-table-column>
      <!-- 合同有效期 -->
      <!-- <el-table-column
        prop="contract_validity"
        label="合同有效期"
        width="180"
        align="center"
      >
      </el-table-column> -->
      <!-- 首次合作时间 -->
      <el-table-column
        prop="first_time"
        label="首次合作时间"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.first_time === ''"></span>
          <span v-else>{{ scope.row.first_time | dateFormat }}</span>
        </template>
      </el-table-column>
      <!-- 月费用 -->
      <!-- <el-table-column
        prop="monthly_cost"
        label="月费用"
        width="180"
        align="center"
      >
      </el-table-column> -->
      <!-- 服务期限 -->
      <el-table-column prop="term" label="服务期限" width="180" align="center">
      </el-table-column>
      <!-- 费用开始时间 -->
      <el-table-column
        prop="start_time"
        label="费用开始时间"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.start_time === ''"></span>
          <span v-else>{{ scope.row.start_time | dateFormat }}</span>
        </template>
        <template>
          <span
            v-if="this.$store.state.adminlist.role_id === 11"
            style="color: red"
            >*无权限*</span
          >
        </template>
      </el-table-column>
      <!-- 费用到期时间 -->
      <el-table-column
        prop="end_time"
        label="费用到期时间"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.end_time === ''"></span>
          <span v-else>{{ scope.row.end_time | dateFormat }}</span>
        </template>
      </el-table-column>

      <!-- 联系人 -->
      <el-table-column
        prop="contacts"
        label="联系人"
        width="180"
        align="center"
      >
        <template>
          <span
            v-if="
              this.$store.state.adminlist.role_id === 6 ||
              this.$store.state.adminlist.role_id === 10 ||
              this.$store.state.adminlist.role_id === 11
            "
            style="color: red"
            >*无权限*</span
          >
        </template>
      </el-table-column>
      <!-- 手机号码 -->
      <el-table-column
        prop="contact_tel"
        label="手机号码"
        width="180"
        align="center"
      >
        <template>
          <span
            v-if="
              this.$store.state.adminlist.role_id === 6 ||
              this.$store.state.adminlist.role_id === 10 ||
              this.$store.state.adminlist.role_id === 11
            "
            style="color: red"
            >*无权限*</span
          >
        </template>
      </el-table-column>
      <!-- 备用联系人 -->
      <el-table-column
        prop="spare_contacts"
        label="备用联系人"
        width="180"
        align="center"
      >
        <template>
          <span
            v-if="
              this.$store.state.adminlist.role_id === 6 ||
              this.$store.state.adminlist.role_id === 10 ||
              this.$store.state.adminlist.role_id === 11
            "
            style="color: red"
            >*无权限*</span
          >
        </template>
      </el-table-column>
      <!-- 备用联系电话 -->
      <el-table-column
        prop="spare_contact_tel"
        label="备用联系电话"
        width="180"
        align="center"
      >
        <template>
          <span
            v-if="
              this.$store.state.adminlist.role_id === 6 ||
              this.$store.state.adminlist.role_id === 10 ||
              this.$store.state.adminlist.role_id === 11
            "
            style="color: red"
            >*无权限*</span
          >
        </template>
      </el-table-column>
      <!-- 合作状态 -->
      <!-- <el-table-column
        prop="cooperation_status"
        label="合作状态"
        width="180"
        align="center"
      >
      </el-table-column> -->
      <!-- 暂停原因 -->
      <!-- <el-table-column
        prop="susps_reason"
        label="暂停原因"
        width="180"
        align="center"
      >
      </el-table-column> -->
      <!-- 地址 -->
      <el-table-column prop="address" label="地址" width="180" align="center">
        <template>
          <span
            v-if="
              this.$store.state.adminlist.role_id === 6 ||
              this.$store.state.adminlist.role_id === 10 ||
              this.$store.state.adminlist.role_id === 11
            "
            style="color: red"
            >*无权限*</span
          >
        </template>
      </el-table-column>
      <!-- 纳税识别号 -->
      <el-table-column
        prop="tax_discern_num"
        label="纳税识别号"
        width="180"
        align="center"
      >
      </el-table-column>
      <!-- 个税密码 -->
      <el-table-column
        prop="personal_tax_pass"
        label="个税密码"
        width="180"
        align="center"
      >
        <template>
          <span
            v-if="
              this.$store.state.adminlist.role_id === 5 ||
              this.$store.state.adminlist.role_id === 6 ||
              this.$store.state.adminlist.role_id === 9 ||
              this.$store.state.adminlist.role_id === 11
            "
            style="color: red"
            >*无权限*</span
          >
        </template>
      </el-table-column>
      <!-- 电子税务局密码 -->
      <el-table-column
        prop="electron_tax_pass"
        label="电子税务局密码"
        width="180"
        align="center"
      >
        <template>
          <span
            v-if="
              this.$store.state.adminlist.role_id === 5 ||
              this.$store.state.adminlist.role_id === 6 ||
              this.$store.state.adminlist.role_id === 9 ||
              this.$store.state.adminlist.role_id === 11
            "
            style="color: red"
            >*无权限*</span
          >
        </template>
      </el-table-column>
      <!-- 法人身份证 -->
      <el-table-column
        prop="id_number"
        label="法人身份证"
        width="180"
        align="center"
      >
        <template>
          <span
            v-if="
              this.$store.state.adminlist.role_id === 5 ||
              this.$store.state.adminlist.role_id === 6 ||
              this.$store.state.adminlist.role_id === 9 ||
              this.$store.state.adminlist.role_id === 11
            "
            style="color: red"
            >*无权限*</span
          >
        </template>
      </el-table-column>
      <!-- 回单卡密码 -->
      <el-table-column
        prop="card_list_pass"
        label="回单卡密码"
        width="180"
        align="center"
      >
        <template>
          <span
            v-if="
              this.$store.state.adminlist.role_id === 5 ||
              this.$store.state.adminlist.role_id === 6 ||
              this.$store.state.adminlist.role_id === 9 ||
              this.$store.state.adminlist.role_id === 11
            "
            style="color: red"
            >*无权限*</span
          >
        </template>
      </el-table-column>
      <!-- 对公情况 -->
      <el-table-column
        prop="corporate_status"
        label="对公情况"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          {{
            scope.row.corporate_status == "1"
              ? "有"
              : scope.row.corporate_status == "2"
              ? "无"
              : ""
          }}
          <!-- : scope.row.status == "3"
              ? "停报"
              : scope.row.status == "4"
              ? "迁走"
              : scope.row.status == "5"
              ? "注销"
              : scope.row.status == "6"
              ? "转让" -->
        </template>
      </el-table-column>
      <!-- 税盘 -->
      <el-table-column prop="tax_plate" label="税盘" width="180" align="center">
      </el-table-column>
      <!-- 所属税务局 -->
      <el-table-column
        prop="tax_bureau"
        label="所属税务局"
        width="180"
        align="center"
      >
      </el-table-column>
      <!-- 征税方式 -->
      <el-table-column
        prop="taxation_method"
        label="征税方式"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          {{
            scope.row.taxation_method == "查账征收"
              ? "查账征收"
              : scope.row.taxation_method == "简易征收"
              ? "简易征收"
              : scope.row.taxation_method == "差额征收"
              ? "差额征收"
              : scope.row.taxation_method == "免税征收"
              ? "免税征收"
              : scope.row.taxation_method == "定率征收"
              ? "定率征收"
              : scope.row.taxation_method == "定期定额征收"
              ? "定期定额征收"
              : ""
          }}
        </template>
      </el-table-column>
      <!-- 社保情况 -->
      <el-table-column
        prop="security_status"
        label="社保情况"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          {{
            scope.row.security_status == "1"
              ? "有"
              : scope.row.security_status == "2"
              ? "无"
              : ""
          }}
        </template>
      </el-table-column>
      <!-- 操作（固定） -->
      <el-table-column fixed="right" label="操作" width="180" align="center">
        <template slot-scope="scope">
          <!-- 通过 Scoped slot 可以获取到 row, column, $index 和 store（table 内部的状态管理）的数据，用法参考 demo。 -->
          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="seeBtn"
            >详情</el-button
          >
          <!-- <el-button type="button" class="detailsBtn">编辑</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <customer-details
      :dialogVisible="dialogVisible"
      :rowdata="rowdata"
      @dialogVisibleClose="dialogVisibleClose"
    ></customer-details>
  </div>
</template>

<script>
import customerDetails from "./customerdetails.vue";

export default {
  components: {
    customerDetails
  },
  mounted() {
    // console.log(this.customerList);
    // this.getcustomerList()
    // console.log(typeof this.$store.state.adminlist.role_id);
  },
  methods: {
    handleClick(row) {
      this.dialogVisible = true;
      this.rowdata = row;
    },

    // 选择
    resetDateFilter() {
      this.$refs.filterTable.clearFilter("date");
    },
    clearFilter() {
      this.$refs.filterTable.clearFilter();
    },
    dialogVisibleClose(bl) {
      console.log("子组件调用父组件方法");
      this.dialogVisible = bl;
      this.$emit('updateCustomerList')
    },

    // 性质选择
    filterNatureList(value, row) {
      return row.nature == value;
    },
    //  付款类型
    filterpaymenttype(value, row) {
      // 判断 当前的行的paymentType是不是等于选定的值  类似于数组的filter方法
      // console.log(value);
      // console.log(typeof value);
      // console.log(row.pay_type);
      // console.log(typeof row.pay_type);
      return row.pay_type == value;
    }
  },
  props: {
    customerList: {
      type: Array
    }
  },
  data() {
    return {
      //客户列表
      // customerList: [],
      // total: 0,
      // 序号的排序
      index: 1,
      // 详情显示框显示
      dialogVisible: false,
      addUserComShow: false,
      rowdata: {},

      NatureList: [
        { text: "小规模", value: "1" },
        { text: "一般纳税人", value: "2" },
        { text: "小规模个体户", value: "3" },
        { text: "一般纳税人个体户", value: "4" }
      ],
      currentpackageList: [
        { text: "套餐一", value: "1" },
        { text: "套餐二", value: "2" },
        { text: "套餐三", value: "3" },
        { text: "套餐四", value: "4" }
      ],
      paymenttypeList: [
        { text: "微信", value: "微信" },
        { text: "支付宝", value: "支付宝" },
        { text: "现金", value: "现金" },
        { text: "工商", value: "工商" },
        { text: "农行", value: "农行" },
        { text: "建行", value: "建行" },
        { text: "对公", value: "对公" },
        { text: "农行收款码", value: "农行收款码" }
      ]
    };
  }
};
</script>

<style lang="scss">
.table-container {
  .el-button {
    color: #ffffff;
  }
  .el-table th {
    background-color: $index-button-color;
    color: #ffffff;
    font-size: 16px;
    .el-icon-arrow-down:before {
      content: "\e790";
      font-size: 16px;
      color: #ffffff;
    }
  }
  .el-table tr {
    background-color: #ffffff;
  }
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 3px;
  }
  .el-table {
    .el-table__body-wrapper::-webkit-scrollbar {
      width: 10px; /*滚动条宽度*/
      height: 15px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: 0px 1px 3px #f9f9f9e7 inset; /*滚动条的背景区域的内阴影*/
      border-radius: 10px; /*滚动条的背景区域的圆角*/
      background-color: #f9f9f9; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      box-shadow: 0px 1px 3px $index-button-color inset; /*滚动条的内阴影*/
      border-radius: 10px; /*滚动条的圆角*/
      background-color: $index-button-color; /*滚动条的背景颜色*/
    }
  }
}

.seeBtn {
  width: 100px;
  background-color: $index-button-color;
}
.seeBtn:hover {
  background-color: #000000;
}
.detailsBtn {
  width: 100px;
  background-color: $index-buttonPor-color;
}
.detailsBtn:hover {
  background-color: #000000;
}
</style>
