<template>
  <!-- 客户详情dialog -->
  <div class="customerdetails">
    <el-dialog
      append-to-body
      title="客户详情"
      :visible.sync="dialogVisible"
      width="1300px"
      :before-close="dialogVisibleClose"
      @close="dialogVisibleClose"
      class="customerdetails-dialog"
    >
      <div class="customer-details-Container">
        <div class="customer-detailsItem padingBox">
          <div class="customer-details-title">
            {{ rowdata.name }}
            <el-dropdown
              placement="bottom-end"
              trigger="click"
              @command="itemClick"
            >
              <span class="el-dropdown-link">
                <i
                  class="colorItem"
                  :style="{ backgroundColor: dropdownItem.color }"
                ></i
                >{{ dropdownItem.state
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in dropdownOption"
                  :key="index"
                  :command="{ item, index }"
                >
                  <i class="el-icon-check" v-show="index == currentIndex"></i>
                  <i
                    class="colorItem"
                    :style="{ backgroundColor: item.color }"
                  ></i>
                  {{ item.state }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="customer-details-GridBox">
            <!-- 公司编号 -->
            <div class="customer-details-GridItem">
              <span>公司编号:</span
              ><span
                >{{ rowdata.number }}
                <i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.number)"
                ></i>
              </span>
            </div>
            <!-- 联系人 -->
            <div class="customer-details-GridItem">
              <span>联系人:</span>
              <span
                v-if="
                  this.$store.state.adminlist.role_id === 6 ||
                  this.$store.state.adminlist.role_id === 10 ||
                  this.$store.state.adminlist.role_id === 11
                "
                style="color: red"
                >*无权限*</span
              >
              <span v-else
                >{{ rowdata.contacts }}
                <i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.contacts)"
                ></i
              ></span>
            </div>
            <!-- 客服 -->
            <div class="customer-details-GridItem">
              <span>客服:</span
              ><span
                >{{ showService(serviceList, rowdata.service_id)
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.service_id)"
                ></i
              ></span>
            </div>
            <!-- 征税方式 -->
            <div class="customer-details-GridItem">
              <span>征税方式:</span
              ><span
                >{{ rowdata.taxation_method }}

                <i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.taxation_method)"
                ></i
              ></span>
            </div>
            <!-- 公司性质 -->
            <div class="customer-details-GridItem">
              <span>公司性质:</span
              ><span>
                {{ natureValu(rowdata.nature) }}
                <i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.nature)"
                ></i
              ></span>
            </div>
            <!-- 手机号码 -->
            <div class="customer-details-GridItem">
              <span>手机号码:</span>
              <span
                v-if="
                  this.$store.state.adminlist.role_id === 6 ||
                  this.$store.state.adminlist.role_id === 10 ||
                  this.$store.state.adminlist.role_id === 11
                "
                style="color: red"
                >*无权限*</span
              >
              <span v-else
                >{{ rowdata.contact_tel
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.contact_tel)"
                ></i
              ></span>
            </div>
            <!-- 财务会计 -->
            <div class="customer-details-GridItem">
              <span>账务会计:</span
              ><span
                >{{ showService(finances, rowdata.finance_id)
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.finance_id)"
                ></i
              ></span>
            </div>
            <!-- 对公情况 -->
            <div class="customer-details-GridItem">
              <span>对公情况:</span
              ><span>
                {{ toPublic(rowdata.corporate_status) }}
                <i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.corporate_status)"
                ></i
              ></span>
            </div>
            <!-- 区域 -->
            <div class="customer-details-GridItem">
              <span>区域:</span
              ><span>
                {{ areaVal(rowdata.area_id) }}
                <i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.area_id)"
                ></i
              ></span>
            </div>
            <!-- 备用联系人 -->
            <div class="customer-details-GridItem">
              <span>备用联系人:</span>
              <span
                v-if="
                  this.$store.state.adminlist.role_id === 6 ||
                  this.$store.state.adminlist.role_id === 10 ||
                  this.$store.state.adminlist.role_id === 11
                "
                style="color: red"
                >*无权限*</span
              >
              <span v-else
                >{{ rowdata.spare_contacts
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.spare_contacts)"
                ></i
              ></span>
            </div>
            <!-- 税务会计 -->
            <div class="customer-details-GridItem">
              <span>税务会计:</span
              ><span
                >{{ showService(revenues, rowdata.tax_id)
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.tax_id)"
                ></i
              ></span>
            </div>
            <!-- 社保情况 -->
            <div class="customer-details-GridItem">
              <span>社保情况:</span
              ><span>
                {{ socialVal(rowdata.security_status) }}
                <i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.security_status)"
                ></i
              ></span>
            </div>
            <!-- 地址 -->
            <div class="customer-details-GridItem">
              <span>地址:</span
              ><span
                >{{ rowdata.address
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.address)"
                ></i
              ></span>
            </div>
            <!-- 备用联系电话 -->
            <div class="customer-details-GridItem">
              <span>备用联系电话:</span>
              <span
                v-if="
                  this.$store.state.adminlist.role_id === 6 ||
                  this.$store.state.adminlist.role_id === 10 ||
                  this.$store.state.adminlist.role_id === 11
                "
                style="color: red"
                >*无权限*</span
              >
              <span v-else
                >{{ rowdata.spare_contact_tel
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.spare_contact_tel)"
                ></i
              ></span>
            </div>
            <!-- 个税密码 -->
            <div class="customer-details-GridItem">
              <span>个税密码:</span>
              <span
                v-if="
                  this.$store.state.adminlist.role_id === 5 ||
                  this.$store.state.adminlist.role_id === 6 ||
                  this.$store.state.adminlist.role_id === 9 ||
                  this.$store.state.adminlist.role_id === 11
                "
                style="color: red"
                >*无权限*</span
              >
              <span v-else>
                {{ rowdata.personal_tax_pass }}
                <i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.personal_tax_pass)"
                >
                </i>
              </span>
            </div>
            <!-- 所属税务局 -->
            <div class="customer-details-GridItem">
              <span>所属税务局:</span
              ><span
                >{{ rowdata.tax_bureau
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.tax_bureau)"
                ></i
              ></span>
            </div>
            <!-- 备注 -->
            <!-- <div class="customer-details-GridItem">
              <span>备注:</span
              ><span
                ><i class="el-icon-edit" @click="openMessageBox()"></i
              ></span>
            </div> -->
            <!-- 法人身份证 -->
            <div class="customer-details-GridItem">
              <span>法人身份证:</span>
              <span
                v-if="
                  this.$store.state.adminlist.role_id === 5 ||
                  this.$store.state.adminlist.role_id === 6 ||
                  this.$store.state.adminlist.role_id === 9 ||
                  this.$store.state.adminlist.role_id === 11
                "
                style="color: red"
                >*无权限*</span
              >
              <span v-else
                >{{ rowdata.id_number
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.id_number)"
                ></i
              ></span>
            </div>
            <!-- 暂停原因 -->
            <div class="customer-details-GridItem">
              <span>暂停原因:</span
              ><span
                >{{ rowdata.susps_reason
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.susps_reason)"
                ></i
              ></span>
            </div>
            <!-- 首次合作时间 -->
            <div class="customer-details-GridItem">
              <span>首次合作时间:</span>
              <span v-if="rowdata.first_time === ''"
                ><i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.first_time)"
                ></i
              ></span>
              <span v-else
                >{{ rowdata.first_time | dateFormat }}
                <i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.first_time)"
                ></i>
              </span>
            </div>
            <!-- 税盘 -->
            <div class="customer-details-GridItem">
              <span>税盘:</span
              ><span
                >{{ rowdata.tax_plate
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.tax_plate)"
                ></i
              ></span>
            </div>
            <!-- 纳税识别号 -->
            <div class="customer-details-GridItem">
              <span>纳税识别号:</span
              ><span
                >{{ rowdata.tax_discern_num
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.tax_discern_num)"
                ></i
              ></span>
            </div>
            <!-- 电子税务局密码 -->
            <div class="customer-details-GridItem">
              <span>电子税务局密码:</span>
              <span
                v-if="
                  this.$store.state.adminlist.role_id === 5 ||
                  this.$store.state.adminlist.role_id === 6 ||
                  this.$store.state.adminlist.role_id === 9 ||
                  this.$store.state.adminlist.role_id === 11
                "
                style="color: red"
                >*无权限*</span
              >
              <span v-else
                >{{ rowdata.electron_tax_pass
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.electron_tax_pass)"
                ></i
              ></span>
            </div>
            <!-- 会卡单密码 -->
            <div class="customer-details-GridItem">
              <span>回卡单密码:</span>
              <span
                v-if="
                  this.$store.state.adminlist.role_id === 5 ||
                  this.$store.state.adminlist.role_id === 6 ||
                  this.$store.state.adminlist.role_id === 9 ||
                  this.$store.state.adminlist.role_id === 11
                "
                style="color: red"
                >*无权限*</span
              >
              <span v-else
                >{{ rowdata.card_list_pass
                }}<i
                  class="el-icon-edit"
                  @click="openMessageBox(newrowdata.card_list_pass)"
                ></i
              ></span>
            </div>
          </div>
          <div class="customer-details-buttonGroup">
            <div class="buttonItem" style="backgroundcolor: #ff7079">客服</div>
            <div class="buttonItem" style="backgroundcolor: #8674eb">会计</div>
            <div class="buttonItem" style="backgroundcolor: #5b56e1">备注</div>
          </div>
        </div>
        <div class="customer-detailsItem padingBox">
          <div class="customer-details-title">
            当前套餐
            <el-button
              type="primary"
              size="small"
              class="amend-btn"
              icon="el-icon-edit"
              @click="showAmendSetmealDialog = true"
              v-show="
                this.$store.state.adminlist.role_id === 1 ||
                this.$store.state.adminlist.role_id === 15
              "
              >修改套餐</el-button
            >
          </div>
          <div class="customer-details-GridBox">
            <!-- 本期套餐 -->
            <div class="customer-details-GridItem">
              <span
                >本期套餐:
                <span>{{
                  (rowdata.pay_money * 1).toFixed(0) + ` 元`
                }}</span></span
              >
            </div>
            <!-- 月费率 -->
            <div class="customer-details-GridItem">
              <span
                >月费率:<span
                  >{{
                    (rowdata.pay_money / rowdata.term).toFixed(2)
                  }}元/月</span
                ></span
              >
            </div>
            <!-- 服务期限（个月） -->
            <div class="customer-details-GridItem">
              <span
                >服务期限（个月）: <span>{{ rowdata.term }}</span></span
              >
            </div>
            <!-- 费用开始时间 -->
            <div class="customer-details-GridItem">
              <span
                >费用开始时间: <span>{{ rowdata.start_time }}</span></span
              >
            </div>
            <!-- 合同有效期 -->
            <!-- <div class="customer-details-GridItem">
              <span
                >合同有效期:<span>{{ rowdata.contract_validity }}</span></span
              >
            </div> -->
            <!-- 套餐金额 -->
            <!-- <div class="customer-details-GridItem">
              <span>套餐金额:<span>300</span></span>
            </div> -->
            <!-- 费用到期时间 -->
            <div class="customer-details-GridItem">
              <span
                >费用到期时间: <span>{{ rowdata.end_time }}</span></span
              >
            </div>
            <!-- 付款类型 -->
            <div class="customer-details-GridItem">
              <span
                >付款类型:
                <span>
                  {{ city(rowdata.pay_type) }}
                </span></span
              >
            </div>
          </div>
        </div>
        <div class="customer-detailsItem padingBox">
          <!-- 记录tab -->
          <el-tabs type="border-card">
            <!-- 沟通记录 -->
            <el-tab-pane>
              <span slot="label" name="first"
                ><i class="iconfont icon-goutongjilu"></i> 沟通记录</span
              >
              <!-- 沟通记录tab -->
              <communicateRecord-com
                :remarkslist="remarkslist"
                :remarks="remarks"
              ></communicateRecord-com>
            </el-tab-pane>
            <!-- 缴费记录 -->
            <el-tab-pane>
              <span slot="label" name="second"
                ><i class="iconfont icon-jiaofeijilu"></i> 缴费记录</span
              >
              <!-- 缴费记录内容 -->
              <paymentrecord-com
                :packageChanges="packageChanges"
              ></paymentrecord-com>
            </el-tab-pane>
            <!-- 交接记录 -->
            <el-tab-pane>
              <span slot="label" name="third"
                ><i class="iconfont icon-iconku-"></i> 交接记录</span
              >
              <!-- 交接记录内容 -->
              <connect-records
                :records="records.filter((item) => item.connect)"
              ></connect-records>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label" name="fourth"
                ><i class="iconfont icon-iconku-"></i> 修改记录</span
              >
              <!-- 修改记录内容 -->
              <amendant-record
                :records="records.filter((item) => !item.connect)"
              ></amendant-record>
            </el-tab-pane>
          </el-tabs>
          <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        </el-tabs> -->
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span> -->
    </el-dialog>
    <!-- 修改对话框 -->
    <el-dialog
      width="30%"
      top="10%"
      title="修改内容"
      :visible.sync="editVisible"
      @close="exitDialog"
      append-to-body
      custom-class="messagediglog-box"
    >
      <el-form :model="ruleForm" ref="ruleForm">
        <el-form-item label="原数据:">
          <span class="message">{{ showMessagediglogData(dialogValue) }}</span>
        </el-form-item>
        <el-form-item
          :rules="mateRules(dialogValue.key)"
          label="修改数据:"
          prop="newValue"
        >
          <div class="input">
            <!-- 显示修改社保情况的选择框 -->
            <el-select
              v-if="dialogValue.key === 'security_status'"
              v-model="newValue"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="有" value="1"></el-option>
              <el-option label="无" value="2"></el-option>
            </el-select>
            <!-- 显示修改区域的选择框 -->
            <el-select
              v-else-if="dialogValue.key === 'area_id'"
              v-model="newValue"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                v-for="item in areaList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
            <!-- 显示修改对公情况的选择框 -->
            <el-select
              v-else-if="dialogValue.key === 'corporate_status'"
              v-model="newValue"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="有" value="1"></el-option>
              <el-option label="无" value="2"></el-option>
              <!-- <el-option label="停报" value="3"></el-option>
              <el-option label="迁走" value="4"></el-option>
              <el-option label="注销" value="5"></el-option>
              <el-option label="转让" value="6"></el-option> -->
            </el-select>
            <!-- 显示修改公司性质的选择框 -->
            <el-select
              v-else-if="dialogValue.key === `nature`"
              v-model="newValue"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="小规模" value="1"></el-option>
              <el-option label="一般纳税人" value="2"></el-option>
              <el-option label="小规模个体户" value="3"></el-option>
              <el-option label="一般纳税人个体户" value="4"></el-option>
            </el-select>
            <!-- 显示修改客服下拉框 -->
            <el-select
              v-else-if="dialogValue.key === `service_id`"
              v-model="newValue"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                v-for="item in serviceList"
                :key="item.id"
                :label="item.real_name + `(${item.area_name})`"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 显示财务会记下拉框 -->
            <el-select
              v-else-if="dialogValue.key === 'finance_id'"
              v-model="newValue"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                v-for="item in finances"
                :key="item.id"
                :label="item.real_name"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 显示税务会记下拉框 -->
            <el-select
              v-else-if="dialogValue.key === 'tax_id'"
              v-model="newValue"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                v-for="item in revenues"
                :key="item.id"
                :label="item.real_name"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 显示征税方式下拉框 -->
            <el-select
              v-else-if="dialogValue.key === 'taxation_method'"
              v-model="newValue"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="查账征收" value="查账征收"></el-option>
              <el-option label="简易征收" value="简易征收"></el-option>
              <el-option label="差额征收" value="差额征收"></el-option>
              <el-option label="免税征收" value="免税征收"></el-option>
              <el-option label="定率征收" value="定率征收"></el-option>
              <el-option label="定期定额征收" value="定期定额征收"></el-option>
            </el-select>
            <!-- 显示修改时间输入框 -->
            <el-date-picker
              v-else-if="dialogValue.key === 'first_time'"
              v-model="newValue"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
            <!-- 默认显示的输入框 -->
            <el-input v-else class="message" v-model="ruleForm.newValue">
            </el-input>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="subRowdata">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改套餐对话框 -->
    <amend-setmeal-dialog
      :showAmendSetmealDialog="showAmendSetmealDialog"
      @quitDialog="quitDialog"
      @
      :u_id="rowdata.id"
      @updateSetMeal="updateSetMeal"
    ></amend-setmeal-dialog>
  </div>
</template>

<script>
import paymentrecordCom from "./paymentrecordCom.vue";
import communicateRecordCom from "./communicateRecordCom.vue";
import amendantRecord from "./amendantRecord.vue";
import amendSetmealDialog from "./amendSetmealDialog.vue";
import { clienteleInfo } from "../../constants/index";
import connectRecords from "./connectRecords.vue";
import { formatDate } from "../../utils/formatDate";

export default {
  components: {
    paymentrecordCom,
    communicateRecordCom,
    amendantRecord,
    amendSetmealDialog,
    connectRecords,
  },
  data() {
    return {
      // 当前客户详情id
      editVisible: false,
      currentIndex: 0,
      dropdownItem: { color: "#4ecbe3", state: "正常" },
      dropdownOption: [
        { color: "#4ecbe3", state: "正常" },
        { color: "#ff7079", state: "欠费" },
        { color: "#999999", state: "停报" },
        { color: "#5b56e1", state: "迁走" },
        { color: "#999999", state: "注销" },
        { color: "#8674eb", state: "转让" },
      ],
      activeName: "first",
      dialogVisibleflag: this.dialogVisible,
      remarkslist: {
        id: "",
      },
      remarks: [],
      // 对话框原数据
      dialogValue: {},
      // 修改的新数据
      newValue: null,
      // 客服列表
      serviceList: [],
      // 区域列表
      areaList: [],
      // 财务会记列表
      finances: [],
      // 税务会记列表
      revenues: [],
      // form data
      ruleForm: {
        newValue: "",
      },
      // 验证规则
      rules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },
        ],
        password: [],
        id_number: [],
      },
      fee_rate: "",
      // 显示修改套餐对话框
      showAmendSetmealDialog: false,
      // 修改记录
      records: [],
      // 修改套餐记录
      packageChanges: [],
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    rowdata: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    // 处理地址数据
    city() {
      return (type) => {
        switch (type) {
          case "微信":
            return "微信";
          case "支付宝":
            return "支付宝";
          case "现金":
            return "现金";
          case "农行":
            return "农行";
          case "建行":
            return "建行";
          case "对公":
            return "对公";
          case "工商":
            return "工商";
          case "农行收款码":
            return "农行收款码";
          default:
            return "";
        }
      };
    },
    // 格式化 rowdata
    newrowdata() {
      const newrowdata = { ...this.rowdata };
      for (const key in newrowdata) {
        newrowdata[key] = {
          key,
          value: newrowdata[key],
        };
      }
      return newrowdata;
    },
    // 显示客服选择框内容
    showService() {
      return (arr, id) => {
        let name = "";
        arr.map((item) => {
          if (item.id == id) {
            name = item.real_name;
          }
        });
        return name;
      };
    },
    // 处理社保数据
    socialVal() {
      return (id) => {
        if (id == "1") {
          return "有";
        } else if (id == "2") return "无";
      };
    },
    // 处理公司性质数据
    natureValu() {
      return (id) => {
        switch (id * 1) {
          case 1:
            return "小规模";
          case 2:
            return "一般纳税人";
          case 3:
            return "小规模个体户";
          case 4:
            return "一般纳税个体户";

          default:
            return "";
        }
      };
    },
    // 处理对公情况数据
    toPublic() {
      return (id) => {
        switch (id * 1) {
          case 1:
            return "有";

          case 2:
            return "无";

          // case 3:
          //   return "停报";

          // case 4:
          //   return "迁走";

          // case 5:
          //   return "注销";

          // case 6:
          //   return "转让";

          default:
            return "";
        }
      };
    },
    // 处理区域数据
    areaVal() {
      return (id) => {
        for (let i = 0; i < this.areaList.length; i++) {
          if (this.areaList[i].id == id) {
            return this.areaList[i].name;
          }
        }
      };
    },
    // 显示原始数据
    showMessagediglogData() {
      return ({ key, value }) => {
        switch (key) {
          case "service_id":
            return this.showService(this.serviceList, value);
          case "security_status":
            return this.socialVal(value);
          case "corporate_status":
            return this.toPublic(value);
          case "nature":
            return this.natureValu(value);
          case "area_id":
            return this.areaVal(value);
          case "finance_id":
            return this.showService(this.finances, value);
          case "tax_id":
            return this.showService(this.revenues, value);
          default:
            return value;
        }
      };
    },
    // 匹配输入框校验规则
    mateRules() {
      return (key) => {
        if (key === "contact_tel" || key === "spare_contact_tel")
          return this.rules["phone"];
        return [];
      };
    },
  },
  watch: {
    rowdata: function (newVal) {
      this.remarkslist.id = newVal.id;
      this.remarks = newVal.remarks;
      // console.log(oldVal);
      // console.log(this.remarkslist.id);
      // console.log(this.remarks);
      // console.log(newVal);
      // newVal && this.getUserEditList();//newVal值存在的时候执行的函数
    },
    dialogVisible(val, newVal) {
      console.log(val, newVal);
      if (this.dialogVisible) {
        this.requestRecords();
        this.requestPackageChange();
      }
    },
  },
  created() {
    this.getServiceList();
    this.getArea();
    this.getFinances();
    this.getRevenues();
  },

  methods: {
    itemClick(item) {
      console.log(item);
      this.dropdownItem = item.item;
      this.currentIndex = item.index;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    dialogVisibleClose() {
      // console.log('dialogVisibleClose的回调');
      this.$emit("dialogVisibleClose", false);
    },
    // 验证打开的修改对话框当前角色是否有权限
    isRoleOpenDialog() {
      const { key } = this.dialogValue;
      const roleKyes = ["service_id", "tax_id", "finance_id", "first_time"];
      let isRoleKye = roleKyes.filter((item) => item === key).length > 0;
      console.log(isRoleKye);
      return isRoleKye;
    },
    // 判断当前角色权限是否为高权限的
    isrole() {
      const { role_id: id } = this.$store.getters.adminlist;
      const role_ids = [1, 6, 9, 10];
      let isrole = role_ids.filter((role_id) => role_id === id).length > 0;
      console.log(isrole);
      return isrole;
    },
    // 弹出修改客户详情数据对话框
    openMessageBox(value) {
      this.dialogValue = value;
      if (this.isRoleOpenDialog()) {
        if (!this.isrole()) return;
        console.log(this.rowdata);
        console.log(this.dialogValue);
        this.editVisible = true;
      } else {
        console.log(this.rowdata);
        console.log(this.dialogValue);
        this.editVisible = true;
      }
    },
    // 提交修改客户详情
    async subRowdata() {
      if (!this.newValue && !this.ruleForm.newValue) {
        this.newValue = "";
        this.ruleForm.newValue = "";
        // this.$refs.ruleForm.validate((valid) => {
        //   console.log(valid);
        // });
        console.log("aaa");
        this.$refs.ruleForm.resetFields();
        this.editVisible = false;
        return;
      }
      // 发起修改请求
      await this.editRowData();
    },
    // 发送修改请求前
    async editRowData() {
      let val = "";
      const { key } = this.dialogValue;

      if (this.newValue) {
        val = this.newValue;
        this.rowdata[key] = this.newValue;
        console.log(val);
      } else {
        val = this.ruleForm.newValue;
        this.rowdata[key] = this.ruleForm.newValue;
        console.log(val);
      }
      if (key === "first_time") {
        val = new Date(val + "").getTime() / 1000;
      } else if (key === "tax_id") {
        console.log("tax_accounting");
        this.updateAccounting(this.revenues, "tax_accounting");
      } else if (key === "finance_id") {
        this.updateAccounting(this.finances, "finance_accounting");
      }
      await this.editResponse(key, val);
    },
    // 发送修改请求
    async editResponse(key, val) {
      const { id } = this.rowdata;
      const { token, id: service_id } = this.$store.getters.adminlist;
      try {
        const {
          data: { code, msg },
        } = await this.$http({
          method: "POST",
          url: "/Customer/edit",
          data: {
            id,
            field: key,
            val,
            a_id: service_id,
            service_id,
            token,
          },
        });
        if (code !== 200) {
          this.$message.error(msg);
          this.ruleForm.newValue = "";
          this.newValue = "";
        }
        this.$message.success(msg);
        this.requestRecords();
        this.editVisible = false;
        this.ruleForm.newValue = "";
        this.newValue = "";
      } catch (error) {
        this.$message.error("未知错误");
      }
    },
    // 修改会计
    updateAccounting(arr, field) {
      const { id } = this.rowdata;
      const { token, id: service_id } = this.$store.getters.adminlist;
      for (let i = 0; i < arr.length; i++) {
        console.log(arr[i].real_name);
        if (arr[i].id == this.newValue) {
          this.$http({
            method: "POST",
            url: "/Customer/edit",
            data: {
              id,
              field,
              val: arr[i].real_name,
              a_id: service_id,
              service_id,
              token,
            },
          });
        }
      }
    },
    // 退出对话框事件
    exitDialog() {
      this.newValue = "";
      this.$refs.ruleForm.resetFields();
    },
    // 获取区域列表
    async getArea() {
      this.areaList = await this.response("/Area/index");
    },
    // get 请求封装
    async response(url) {
      const { token, id: a_id } = this.$store.getters.adminlist;
      const res = await this.$http.get(url, {
        params: {
          a_id,
          token,
        },
      });
      return res.data.data;
    },
    // 获取客服列表
    async getServiceList() {
      this.serviceList = await this.response("/Admin/getService");
    },
    // 获取财务会记列表
    async getFinances() {
      this.finances = await this.response("/Admin/getFinanceAccounting");
      console.log(this.finances);
    },
    // 获取税务会记列表
    async getRevenues() {
      this.revenues = await this.response("/Admin/getTaxAccounting");
      console.log(this.revenues);
    },
    // 退出修改套餐对话框的回调
    quitDialog(isDis) {
      this.showAmendSetmealDialog = isDis;
    },
    // 本地更新套餐数据
    updateSetMeal({ start_time, end_time, pay_money, pay_type, term }) {
      this.rowdata.term = term;
      this.rowdata.start_time = start_time;
      this.rowdata.end_time = end_time;
      this.rowdata.pay_money = pay_money;
      this.rowdata.pay_type = pay_type;
      this.requestPackageChange();
    },
    // 获取修改记录表
    async requestRecords() {
      const { token, id: a_id } = this.$store.getters.adminlist;
      const { id: customer_id } = this.rowdata;

      const res = await this.$http({
        url: "/Customer/getRevise",
        method: "POST",
        data: {
          a_id,
          customer_id,
          token,
        },
      });
      if (!res.data.code === 200) return this.$message.error(res.data.msg);
      try {
        if (res.data.data.records) {
          this.records = this.formatRecords(res.data.data);
          // this.crecords = this.records.filter((item) => !item.connect)
        }
      } catch (error) {
        this.records = [];
        // this.crecords = []
      }
      // this.records = this.formatRecords(res.data.data);;
      console.log("修改记录", this.records);
    },
    // 格式化修改记录数据
    formatRecords({ records }) {
      return records.map((item) => {
        for (const key in clienteleInfo) {
          if (key === item.field) {
            console.log(item.fieldVal);
            item.fieldVal = clienteleInfo[key];
          }
        }
        switch (item.field) {
          case "nature":
            item.field_val = this.natureValu(item.field_val);
            item.old_field = this.natureValu(item.old_field);
            break;
          case "area_id":
            item.field_val = this.areaVal(item.field_val);
            item.old_field = this.areaVal(item.old_field);
            break;
          case "security_status":
            item.field_val = this.socialVal(item.field_val);
            item.old_field = this.socialVal(item.old_field);
            break;
          case "corporate_status":
            item.field_val = this.toPublic(item.field_val);
            item.old_field = this.toPublic(item.old_field);
            break;
          case "service_id":
            item.field_val = this.showService(this.serviceList, item.field_val);
            item.old_field = this.showService(this.serviceList, item.old_field);
            break;
          case "finance_id":
            item.field_val = this.showService(this.finances, item.field_val);
            item.old_field = this.showService(this.finances, item.old_field);
            break;
          case "tax_id":
            item.field_val = this.showService(this.revenues, item.field_val);
            item.old_field = this.showService(this.revenues, item.old_field);
            break;
          case "first_time":
            item.field_val = formatDate(item.field_val * 1000);
            break;
          default:
            break;
        }
        if (
          item.field === "service_id" ||
          item.field === "tax_id" ||
          item.field === "finance_id"
        ) {
          item.connect = true;
          return item;
        } else {
          return item;
        }
      });
    },
    // 获取修改套餐记录
    async requestPackageChange() {
      const { token, id: a_id } = this.$store.getters.adminlist;
      const { id: customer_id } = this.rowdata;

      const { data: result } = await this.$http.post("/SetMeal/setMealRecord", {
        customer_id,
        token,
        a_id,
      });

      if (result.code == 1) {
        this.packageChanges = this.formatPackageChange(result.data);
        console.log(this.packageChanges);
      } else if (result.code != 1 && result.code != 201) {
        this.$message.error(result.msg);
      }
    },
    // 格式化修改套餐数据
    formatPackageChange(data) {
      return data.map((item) => {
        item.start_time = formatDate(item.start_time * 1000);
        item.money = (item.money * 1).toFixed(0) + "元";
        return item;
      });
    },
  },
};
</script>

<style lang="scss">
.customer-details-buttonGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  .buttonItem {
    width: 60px;
    height: 30px;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}
.el-form-item__content {
  display: flex;
  .input {
    width: 100%;
  }
}
.colorItem {
  display: inline-block !important;
  content: "" !important;
  height: 10px !important;
  width: 10px !important;
  background-color: $index-button-color;
  border-radius: 50%;
  margin-right: 10px;
}
.customerdetails-dialog {
  .el-dialog {
    position: relative;
    .el-dialog__header {
      background-color: $index-button-color;
      .el-dialog__title {
        color: #ffffff;
        font-size: 20px;
        font-weight: bold;
      }
      .el-dialog__close {
        color: #ffffff;
      }
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}

.amend-btn {
  background: #8674eb;
  border-color: #8674eb;
  span {
    margin: 5px !important;
  }
}

.padingBox {
  padding: 30px 20px;
}
.el-dialog__body {
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 2px;
  }
  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/

    border-radius: 5px;

    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

    background: $index-button-color;
  }
  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/

    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

    border-radius: 0;

    background: rgba(0, 0, 0, 0.1);
  }
}
.customer-details-Container {
  height: 800px;
  overflow: auto;

  .el-tabs--border-card {
    border: none;
    box-shadow: none;
  }
  .el-tabs__header {
    border: none;
    background-color: #ffffff;
  }
  .el-tabs__content {
    padding: 0;
  }
  .is-active {
    border-radius: 6px 6px 0 0;
    background-color: $index-button-color !important;
    color: #ffffff !important;
  }

  .customer-details-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: bold;
    color: #333333;
    justify-content: space-between;

    span {
      margin-left: 30px;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .customer-detailsItem {
    border-bottom: 2px solid #f2f2f2;
  }
  .customer-detailsItem:last-child {
    border-bottom: none;
  }
  .customer-details-GridBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    .customer-details-GridItem {
      display: flex;
      align-items: center;
      font-size: 16px;
      justify-content: flex-start;
      span:first-child {
      }
      span:nth-child(2) {
        flex: 1;
        margin-left: 10px;
      }
      i {
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }
}
.messagediglog-box {
  border: none;
  .el-message-box__header {
    background-color: $index-buttonPor-color;
    .el-message-box__title {
      color: #ffffff;
    }
  }
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    .el-dialog__title {
      color: #ffffff;
    }
  }
  .el-message-box__close {
    color: #ffffff;
  }
  .el-message-box__btns {
    .cancelBtn {
      color: $index-buttonPor-color;
    }
    .confirmBtn {
      background-color: $index-buttonPor-color;
    }
  }
  .messageBox {
    .messageItem {
      display: flex;
      margin-bottom: 20px;
      .messageTitle {
        width: 80px;
        text-align: left;
      }
      .message {
        flex: 1;
        margin-left: 10px;
      }
    }
  }
}
</style>
